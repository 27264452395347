import React from "react";
import { graphql, Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Img from "gatsby-image";
import styled from "styled-components";
import { AnimationOnScroll } from "react-animation-on-scroll";
import nextArrow from "../assets/next-arrow.svg";
import Share from "../components/share";
import Seo from "../components/SEO";
import Started from "../components/Layout/started";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import YouMayAlsoLike from "../components/youMayAlsoLike";

import * as styles from "./postLayout.module.less";

const ImageDiv = styled.div`
  margin-top: -160px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 991px) {
    margin-top: -80px;
  }
  @media screen and (max-width: 768px) {
    margin-top: -100px;
  }
`;

const StyledTitle = styled.h1`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 25px;
  @media screen and (max-width: 991px) {
    margin-top: 45px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 30px;
    font-size: 20px;
  }
`;

const StyledText = styled.div`
  font-size: 16px;
  letter-spacing: 0.03px;
  line-height: 1.63;
  color: #242d41;
  p {
    margin-bottom: 1.5rem;
  }
  h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  a {
    color: #1e5bb5;
  }
`;

const LinkDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DateWrapper = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: rgb(36, 45, 65);
  margin-top: 70px;
  opacity: 0.7;
`;

const BlogPost = ({ data, path, pageContext }) => {
  const {
    markdownRemark,
    site: {
      siteMetadata: { siteUrl, twitterHandle },
    },
    blogDefault,
  } = data;

  const { frontmatter } = markdownRemark;

  const { next, prev } = pageContext;
  const featuredImgFluid = frontmatter?.featuredImage
    ? frontmatter?.featuredImage?.childImageSharp?.fluid
    : blogDefault?.childImageSharp?.fluid;

  return (
    <Layout>
      <Seo
        title={markdownRemark?.frontmatter?.title}
        image={featuredImgFluid.src}
        description={`Read more about ${frontmatter?.title}`}
      />
      <PageHeader mediumHeader />
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={10} md={11}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeIn"
              offset={0}
            >
              <ImageDiv>
                derp
                <Img fluid={featuredImgFluid} className={styles.topBlogImg} />
              </ImageDiv>
            </AnimationOnScroll>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={6} md={10}>
            <DateWrapper>{frontmatter.date}</DateWrapper>
            <StyledTitle>{frontmatter.title}</StyledTitle>
            <StyledText>
              <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
            </StyledText>
          </Col>
        </Row>
        <Row className={`justify-content-md-center ${styles.shareRow}`}>
          <Col md={6} className={styles.shareCol}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeIn"
              offset={0}
            >
              <Share
                socialConfig={{
                  twitterHandle,
                  config: {
                    url: `${siteUrl}${path}`,
                    title: frontmatter.title,
                  },
                }}
              />
            </AnimationOnScroll>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={6}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInUp"
              offset={0}
            >
              <LinkDiv>
                <Link
                  to={prev ? prev.frontmatter.slug : "/articles/"}
                  className={styles.nextPrevLink}
                >
                  <img src={nextArrow} alt="prevArrow" />{" "}
                  {prev ? "Previous" : "Back to Articles"}{" "}
                </Link>
                <Link
                  to={next ? next.frontmatter.slug : "/articles/"}
                  className={styles.nextPrevLink}
                >
                  {next ? "Next" : "Back to Articles"}
                  <img src={nextArrow} alt="prevArrow" />{" "}
                </Link>
              </LinkDiv>
            </AnimationOnScroll>
          </Col>
        </Row>
        <YouMayAlsoLike currentUrl={frontmatter.slug} />
        <Started />
      </Container>
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query BlogQuery($slug: String!) {
    site {
      siteMetadata {
        twitterHandle
        siteUrl
      }
    }
    blogDefault: file(relativePath: { eq: "Blog/blog_default_fullhd.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        slug
        sample
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
